<template>
  <a v-if="shouldDisplay" :href="imageLink" class="is-flex is-fullwidth mt-3">
    <img
      :src="imageSrc"
      class="is-landing-ad"
      alt="advertisement"
    >
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      ads: (state) => state.advertisements.landing_ads,
    }),
    shouldDisplay() {
      return !!this.ads?.length;
    },
    imageSrc() {
      return Array.from(this.ads)[0].src;
    },
    imageLink() {
      return Array.from(this.ads)[0].link;
    },
  },
};
</script>
